@media (min-width: 1270px) {
  .cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin: 25px 30px 20px 20px;
  }

  .serviceCards .card {
    height: 255px !important;
  }

  
}
