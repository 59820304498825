@import url("pixel_css/1270.css");
@import url("pixel_css/985.css");
@import url("pixel_css/575.css");

.section-title {
  text-transform: uppercase;
  font-weight: 600 !important;
  text-align: center !important;
  font-size: xx-large;
  color: var(--brand_title_color);
}

.navbar-bg {
  background: var(--brand_theme);
  background-color: var(--brand_theme);
  transition: background-color 0.3s ease;
}

.navbar-brand {
  font-weight: 800 !important;
}

.nav-link {
  font-size: smaller !important;
  align-self: center !important;
  color: #fff !important;
}

.about-img {
  width: 72%;
  height: 350px;
  max-height: 350px;
  padding: 17px 25px;
}

hr {
  border: 2px solid #fff;
}

.navbar-collapse {
  flex-grow: 0 !important;
}

.navbar-scroll-item {
  max-height: 200px;
  min-height: 55px;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  overflow: hidden;
}

.about-cover {
  max-width: 100%;
  width: 100%;
  height: 36rem;
  max-height: 36rem;
}

#contactus .row > div {
  margin-top: 1.3rem;
}

.about-div1 {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.about-div2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.about-div1 .about-title {
  font-size: xxx-large;
  font-weight: bold;
  margin-top: 2rem;
  color: #5e0d7e;
}

.about-div1 .about-sub-title {
  font-size: smaller;
  text-align: justify;
  margin-top: 0.8rem;
}

.page-title {
  text-transform: uppercase !important;
  font-weight: 600 !important;
  text-align: center !important;
  font-size: xx-large !important;
  color: var(--brand_title_color) !important;
}
