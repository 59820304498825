.highlightsCards .card {
  width: 180px;
  height: 350px !important;
  margin: 10px 10px;
  border-radius: 10px;
}

.highlightsCards .activeCard {
  border-radius: 5px;
  opacity: 1;
  border: 0px;
  height: 285px;
}

.highlightsCards .card-title {
  font-size: medium !important;
  font-weight: 800 !important;
}

.highlightsCards .card-text {
  font-size: smaller;
  text-align: justify;
  word-spacing: -1px;
}
