/* For screens between 985 pixels and 1270 pixels */
/* CSS rules for medium-sized screens */
@media (min-width: 985px) and (max-width: 1270px) {
  .cards-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    margin: 40px 20px;
    flex-wrap: wrap;
    align-content: center;
  }

  .serviceCards .card {
    /* height: 375px !important; */
  }

  .serviceCards .card img {
    display: visible !important;
  }

  .serviceCardGrid {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    align-content: space-around;
  }

  .serviceCardGrid {
    grid-template-columns: 0fr;
  }
}
