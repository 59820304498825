.serviceCards .card img {
  display: none !important;
}

.serviceCards .card {
  width: 25rem;
  max-height: 450px;
  margin: 15px 10px;
  border-radius: 10px;
  opacity: 0.7;
  /* transition: opacity 0.7s ease-in-out; */
  transition: opacity 0.7s cubic-bezier(0.98, 0.98, 0, -0.06);
}

.serviceCards .card.active {
  transform: scale(1.05);
  /* height: 425px; */
  max-height: 450px;
  background: #5e0d7e;
  z-index: 150;
  border-radius: 5px;
  opacity: 100% !important;
  box-shadow: 3px 5px 5px 1px #7a7a7aa3;
}

.serviceCards .card-title {
  font-size: 0.97rem !important;
  font-weight: 800 !important;
}

.serviceCards .card-text {
  font-size: 0.7rem;
  text-align: justify;
  word-spacing: -1px;
}

.serviceCards .active .card-title {
  color: #fff;
}

.serviceCards .active .card-text {
  color: #dddbdb;
}

.serviceCardsAnimate {
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 0.3s cubic-bezier(0.34, 0.89, 0.44, 0.07),
    transform 0.3s cubic-bezier(0, 0, 0.42, 0.39);
}

.serviceCardsAnimate.visible,
.aboutusCardsAnimate.visible {
  opacity: 1;
  transform: translateY(0);
  transform: translateX(0);
}

.aboutusCardsAnimate {
  opacity: 0;
  transform: translateX(-120px);
  transition: opacity 0.3s cubic-bezier(0.34, 0.89, 0.44, 0.07),
    transform 0.3s cubic-bezier(0, 0, 0.42, 0.39);
}

.serviceCardGrid {
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: auto;
}

.serviceCardGrid .card {
  margin: 14px 20px;
  height: 90%;
  width: 35rem !important;
  max-height: 450px;
  margin: 15px 10px;
  border-radius: 10px;
  opacity: 0.7;
  transition: opacity 0.7s cubic-bezier(0.98, 0.98, 0, -0.06);
  border: 0;
}

.serviceCardGrid .card img {
  display: none !important;
}

.serviceCardGrid .card-title {
  font-weight: 800 !important;
  color: #5e0d7e;
}

.serviceCardGrid .card-text {
  font-size: 0.7rem;
  text-align: justify;
  word-spacing: -1px;
}

.serviceCardBorder-left {
  border-left: 12px solid #5e0d7e !important;
  border-right: 12px solid #5e0d7e !important;
  border-bottom: 1px solid #5e0d7e !important;
  background: linear-gradient(360deg, #5e0d7e87 0%, #5e0d7e4f 1%, #fff);
}

.serviceCardBorder-right {
  border-left: 12px solid #dc3545 !important;
  border-right: 12px solid #dc3545 !important;
  border-bottom: 1px solid #dc3545 !important;
  background: linear-gradient(360deg, #dc354587 0%, #dc35454f 1%, #fff);
}

.serviceCardTitle-right {
  text-align: end;
  color: #dc3545 !important;
}

.serviceCardTitle-left {
  text-align: start;
  color: #5e0d7e !important;
}

.grid-item {
  align-self: stretch;
}

.center-line::before {
  content: "";
  display: block;
  width: 5px;
  height: 100%;
  background: radial-gradient(circle, #9058a500 0%, #e9767e 25%, #9058a5 50%, #e9767e00 75%);
  margin: 0 auto;
}
