/* For screens between 575 pixels and 985 pixels */
/* CSS rules for smaller screens */
@media (min-width: 575px) and (max-width: 985px) {
  .serviceCards .card {
    width: 96% !important;
    max-height: 125px !important;
  }

  .serviceCards .card.active {
    transform: scale(1) !important;
  }

  .serviceCards .card {
    max-height: 125px !important;
  }

  .serviceCards .card img {
    display: none !important;
  }

  .serviceCardGrid {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }

  .serviceCardGrid {
    grid-template-columns: 0fr;
  }
}
